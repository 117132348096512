<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <!-- <vuexy-logo /> -->

          <h2 class="brand-text text-primary ml-1">Arab Investment Club</h2>
        </b-link>

        <b-card-title v-if="success" class="mb-1">
          {{ $t("successPaid.title") }} ✅
        </b-card-title>
        <b-card-text v-if="success" class="mb-2">
          {{ $t("successPaid.header") }}
          <br />
          Arab Investment Club
        </b-card-text>
        <b-card-title class="mb-1" v-if="error">
          {{ $t("errorPaid.title") }} ❌
        </b-card-title>
        <b-card-text v-if="error" class="mb-2">
          {{ $t("errorPaid.header") }}=> {{ error }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form class="auth-forgot-password-form mt-2">
            <!-- email -->
            <!-- <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <!-- submit button -->
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link @click="validationForm">
            <feather-icon icon="ChevronLeftIcon" />
            {{ $t("successPaid.backToPlan") }}
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { BCard, BLink, BCardText, BCardTitle, BForm } from "bootstrap-vue";
import { required, email } from "@validations";

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BForm,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      success: null,
      error: null,
      // validation
      required,
      email,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  created() {
    if (localStorage.getItem("language") == "ar") {
      document.documentElement.setAttribute("dir", "rtl");
      document.body.style.fontFamily = "Tajawal";
    } else {
      document.documentElement.setAttribute("dir", "ltr");
      // document.body.style.fontFamily = 'Montserrat,Helvetica,Arial, serif'
      document.body.style.fontFamily = "nftbplus,sans-serif";
    }
    if (this.$route.params.stripeToken) {
      this.capture(this.$route.params.stripeToken)
        .then((response) => {
          this.success = response.data;
          console.log(response);
        })
        .catch((error) => {
          this.error = error.response.data;
        });
    }
  },
  methods: {
    ...mapActions({
      capture: "profile/capture",
    }),
    validationForm() {
      this.$router.push({
        name: "userprofile",
        params: { username: this.user.username },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
html[dir="rtl"] svg.feather {
  transform: rotate(0);
}
</style>
<style scoped>
.img-fluid {
  max-width: 56%;
}
</style>
